import React from "react";
import Layout from "../components/Layout";
import { Box, Typography } from "@material-ui/core";
import SEO from "../components/SEO";

export default function Error404Template() {
  return (
    <Layout>
      <SEO title="404: Not Found" />
      <Box display="flex" flexDirection="column" flexGrow={1}>
        <Box
          flexGrow={1}
          style={{ alignSelf: "center", justifySelf: "center" }}
          textAlign="center"
          // paddingTop={4}
          // paddingBottom={12}
          // paddingX={8}
          display="flex"
        >
          <Box
            marginBottom={4}
            style={{ alignSelf: "center", justifySelf: "center" }}
          >
            <Typography
              color="textSecondary"
              variant="h1"
              style={{
                fontWeight: "bold",
                fontFamily:
                  "Work Sans, -apple-system, BlinkMacSystemFont, Reem Kufi, sans-serif",
                marginBottom: 4,
                textTransform: "uppercase"
              }}
            >
              404 Error
            </Typography>
            <Typography
              color="textSecondary"
              variant="h6"
              style={{ textTransform: "uppercase" }}
            >
              The requested page was not found.
            </Typography>
          </Box>
        </Box>
      </Box>
    </Layout>
  );
}
